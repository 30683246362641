import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1080p_Series/Alarm/Actions/SuccessBox';
import ActionTable from 'components/Web_User_Interface/1080p_Series/Alarm/Actions/actionTable';
import DangerBox from 'components/Web_User_Interface/1080p_Series/Alarm/Actions/DangerBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Alarm Menu // Alarm Actions",
  "path": "/Web_User_Interface/1080p_Series/Alarm/Actions/",
  "dateChanged": "2017-12-11",
  "author": "Mike Polinowski",
  "excerpt": "Adjust the cameras behaviour in case of a motion alert trigger event. You can set the camera to send you an email and to save snapshots or videos on your SD card. Please make sure you set your SMTP Server settings before activating the email notification and that you have a SD card in your cameras SDHC card slot (max. 32 GB) to use the video recording and send-to-FTP function - a 16 GB SD card is included in the delivery.",
  "image": "./WebUI_1080p_SearchThumb_Alarm_Alarm_Actions.png",
  "social": "/images/Search/WebUI_1080p_SearchThumb_Alarm_Alarm_Actions.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1080p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Alarm Menu // Alarm Actions' dateChanged='2017-12-11' author='Mike Polinowski' tag='INSTAR IP Camera' description='Adjust the cameras behaviour in case of a motion alert trigger event. You can set the camera to send you an email and to save snapshots or videos on your SD card. Please make sure you set your SMTP Server settings before activating the email notification and that you have a SD card in your cameras SDHC card slot (max. 32 GB) to use the video recording and send-to-FTP function - a 16 GB SD card is included in the delivery.' image='/images/Search/WebUI_1080p_SearchThumb_Alarm_Alarm_Actions.png' twitter='/images/Search/WebUI_1080p_SearchThumb_Alarm_Alarm_Actions.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1080p_Serie/Alarm/Aktionen/' locationFR='/fr/Web_User_Interface/1080p_Series/Alarm/Actions/' crumbLabel="Alarm Actions" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1080p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1080p-web-user-interface",
        "aria-label": "1080p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1080p Web User Interface`}</h1>
    <h2 {...{
      "id": "alarm-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarm-menu",
        "aria-label": "alarm menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Menu`}</h2>
    <h3 {...{
      "id": "alarm-actions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#alarm-actions",
        "aria-label": "alarm actions permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Actions`}</h3>
    {/* ## What do you want the Camera to do in Case of an Alarm?
     Adjust the cameras behaviour in case of a motion alert trigger event. You can set the camera to send you an email and to save snapshots or videos on your SD card. Please make sure you set your [SMTP Server Settings](/Web_User_Interface/1080p_Series/System/Email/) before activating the email notification and that you have a [SD Card](/Web_User_Interface/1080p_Series/Features/SD_Card/) in your cameras SDHC card slot (max. 32 GB) to use the alarm and [Planned Recording](/Web_User_Interface/1080p_Series/Recording/) - a 4 GB SD card is included in the delivery. */}
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The software-based motion detection used by your camera for the activated `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Areas/"
      }}>{`Alarm Areas`}</a>{` does not need any additional hardware to work and can be very precise in controlled indoor environments. The disadvantage is that the detection does not discriminate causes for changes inside the cameras field of view - a cloud moving by in front of the sun, raindrops or vegetation moving in the wind. Everything can cause an alarm. Please activate the integrated Passive Infrared Sensor and link it with the software based detection to minimize false alerts.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e531599c67df92a581797f82aee0ca20/29c1d/1080p_Settings_Alarm_Actions.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC1UlEQVQoz22RW0gUURjHp5fAsiTXSha7kiVulyUrwiCtoIjKUArtspZUFJEP7mXmnNkZZ87ollvZjehhd03JXI0e6qGEtkSKxB4Kgh7KUoiiRdseas3ZOefszoldrHzw9/bx5/d98P84PoMAgAg8TqX11LX+k1f6ai9GjvojtZeeOfxPj/ojR/yRWv+TkzdeOH0BRYLHjtcV2VavW1/CeXjBzQsAQrfbdeNWcCSGP40ZI9/Jh6j+5lNsKKp/HDWGY3Qoqg+NGuGOnrOVVcf3HdizqbS0yMbxAmhwC1AUXS5XWyhoUiOJdcbMJME/f4ymKDZpgjFGCdH18de9j9XD9Y2VDmHX1v3rSjgAoIuflIOBACbEMDBj7MsPI/R8TDcIpcQ0TUopZWZf+KFaXX31tGt3mcO+ZjMHp8iBYBBjrOs6Yyw+gd8Oj2V2GYSQVJL+SpDvgw8GXfZ3N0/ck/n6Hes5AUCnhwcQpuVAgFKKMWamOZHAw19jlFJCiGkyQnAyxdqut+xdab1Utaz/TEFvXTHnEcD/y4FAIpGIx8eZSd5/+y3d/xwf1xMT44ylDIxxkjZpLQss1vyFi2zWXGFzHuf2AGemMKfTebu9nTGWMk3GWPSXGRqI06SJGUswRn9PMMZa/S258yzz8yxzZ2ftXJ6VbjvzKQgAUJTGzs6793q6B3rD3V1dd8LdLx89eBW603S+o83f2nf5Qp3jcE6+JXvOrKyZM7ZYZ3AAQjBpKjU11dk5lvLV1rrtBTmWhauWLpZWFoqFy8qLizbu2VZx7lDlqYOlFWX2DfbitbYlK1Zw8C8AAFmWNa3J16QhpCFNa9Y0H9J4hLyKqsiNSFF8vuZMoiGENA1NkSGUJUlDSP0HUhWkakhFGVRVVRRlMsmMnChCEUJRTPuSJCmqqkyJp0XJkJYb3IKLFxrcgocHotcLoAigKIper9crSdK0pizLkiQBAP4AuvTfwhylUAAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e531599c67df92a581797f82aee0ca20/e4706/1080p_Settings_Alarm_Actions.avif 230w", "/en/static/e531599c67df92a581797f82aee0ca20/d1af7/1080p_Settings_Alarm_Actions.avif 460w", "/en/static/e531599c67df92a581797f82aee0ca20/7f308/1080p_Settings_Alarm_Actions.avif 920w", "/en/static/e531599c67df92a581797f82aee0ca20/f5b30/1080p_Settings_Alarm_Actions.avif 1375w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e531599c67df92a581797f82aee0ca20/a0b58/1080p_Settings_Alarm_Actions.webp 230w", "/en/static/e531599c67df92a581797f82aee0ca20/bc10c/1080p_Settings_Alarm_Actions.webp 460w", "/en/static/e531599c67df92a581797f82aee0ca20/966d8/1080p_Settings_Alarm_Actions.webp 920w", "/en/static/e531599c67df92a581797f82aee0ca20/fbb33/1080p_Settings_Alarm_Actions.webp 1375w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e531599c67df92a581797f82aee0ca20/81c8e/1080p_Settings_Alarm_Actions.png 230w", "/en/static/e531599c67df92a581797f82aee0ca20/08a84/1080p_Settings_Alarm_Actions.png 460w", "/en/static/e531599c67df92a581797f82aee0ca20/c0255/1080p_Settings_Alarm_Actions.png 920w", "/en/static/e531599c67df92a581797f82aee0ca20/29c1d/1080p_Settings_Alarm_Actions.png 1375w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e531599c67df92a581797f82aee0ca20/c0255/1080p_Settings_Alarm_Actions.png",
              "alt": "Web User Interface - 1080p Series - Alarm Actions",
              "title": "Web User Interface - 1080p Series - Alarm Actions",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ActionTable mdxType="ActionTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "use-an-external-sensor",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#use-an-external-sensor",
        "aria-label": "use an external sensor permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Use an external Sensor`}</h3>
    <p>{`You can use a motion detector like the `}<a parentName="p" {...{
        "href": "m/Products/IN-Motion/500/"
      }}>{`IN-MOTION 500`}</a>{` to improve the detection rate of your camera (Only cameras with an Alarm-In contact) if your camera does not yet come with a PIR motion sensor equipped like the IN-8015 HD. Simply `}<a parentName="p" {...{
        "href": "/en/Products/IN-Motion/500/Wiring/"
      }}>{`Connect the Sensor`}</a>{` to the alarm input and activate the external input. The camera will trigger an alarm when the pins 3&4 are bridged and the relay state is set to closed on alarm (n.o. “Normally Open”). If it is set to open on alarm, the alarm will be triggered once the circuit is broken by the relay inside the detector (n.c. “Normally Closed”) or if the wire is disconnected. The state you have to choose depends on your sensor - the wrong choice will give a continuous alert once the detector is connected.`}</p>
    <DangerBox mdxType="DangerBox" />
    <br />
    <h3 {...{
      "id": "use-the-internal-sensor-eg-in-8015-hd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#use-the-internal-sensor-eg-in-8015-hd",
        "aria-label": "use the internal sensor eg in 8015 hd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Use the internal Sensor (e.g. IN-8015 HD)`}</h3>
    <p>{`Activating the internal or external PIR sensor allows you to limit the motion detection to bodies whose temperature differs from the background. In most cases, this will be a person or animal in front of the cameras or the warm engine of a car. Changes in light conditions, fog in front of the camera at night or movements in the wind will not be detected by the PIR sensor.`}</p>
    <p>{`By coupling the PIR sensor with the detection areas, you will only get an alarm event if both detection methods were triggered at the same time. This will greatly reduce the number of false alerts you might have. But be aware that it also might eliminate a few positive alerts.`}</p>
    <h3 {...{
      "id": "switch-external-devices-in-case-of-an-alarm-or-manually",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#switch-external-devices-in-case-of-an-alarm-or-manually",
        "aria-label": "switch external devices in case of an alarm or manually permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Switch external devices in case of an alarm (or manually)`}</h3>
    <p>{`The external output connects to a relay inside the camera that can be switched manually or automatically in case of an alarm event. Please check Activate Alarm Output to let the camera open or close a circuit with an external device connected to the pins 1&2. The external output can be used with the cameras motion detection to activate signalling devices like sirens or LED lights. It can also be used to control connected devices manually, e.g. via our smartphone app. The relay can be connected to circuits with up to 60 V direct current / 125 V alternating current and max. 1 A.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      